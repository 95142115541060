<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <ASuccessFour
        :api="this.api"/>
        <v-row>
            <v-col class="mx-auto"
            cols="12"
            sm="7">
                <v-btn
                color="primary"
                @click="()=>{
                    this.$router.go(-1);
                }">
                    <v-icon>
                        mdi-arrow-left-bold
                    </v-icon>
                </v-btn>
                <v-card class="pa-3 mt-3">
                    <v-toolbar
                    class="secondary white--text text-h4 ma-4" flat>
                        <v-toolbar-title class="text-h4">
                            Payment Status Update Form
                        </v-toolbar-title>
                    </v-toolbar>
                    <!-- <ASuccessThree :api="this.api" class="mt-2"/> -->
                    <AError class="mt-2"
                    :api="this.api"/>
                    <v-container>
                        <v-col>
                            <div class="d-flex justify-start mb-5">
                                    {{ company.name }}
                            </div>
                            <!-- <div v-if="attachments!=null">
                                <div class="d-flex justify-start">
                                    Attachment(s) :
                                </div>
                                <div class="d-flex justify-start mb-4"
                                v-for="(attachment,index) in this.attachments"
                                :key="index">
                                    <a :href="attachment.attachment_url" target="_blank" download>
                                        {{ attachment.attachment_name }}
                                    </a>
                                </div>
                            </div> -->
                            <!-- <div class="my-1" v-if="this.attachmentUploader!=null">
                                <div class="d-flex justify-start">
                                    PIC :
                                </div>
                                <div class="d-flex justify-start-mb-3 text-h7 font-weight-bold">
                                    {{ this.attachmentUploader }}
                                </div>
                            </div> -->
                            <div class="mb-3">
                                <v-btn @click="redirectToReceipt" color="primary" class="mb-2">
                                    View all receipts & invoices
                                </v-btn>
                                <v-select
                                label="Payment status :"
                                @change="checkPaymentStatus
                                "
                                :items="paymentStatusOptions"
                                v-model="paymentInfo.status">
                                </v-select>
                            </div>
                            <div v-if="this.isConfirm">
                                
                                <div class="my-1">
                                    <v-select
                                    @change = "checkAmount"
                                    label ="Total payment (RM) :"
                                    :items = "paymentAmountOptions"
                                    v-model="paymentInfo.total_amount">
    
                                    </v-select>
                                    <v-text-field
                                    v-if="this.isOtherAmount"
                                    label="Total payment (RM) :"
                                    v-model="paymentInfo.total_amount">
                                    </v-text-field>
                                </div>
                                <div class="my-1">
                                    <v-select
                                    label="Payment method :"
                                    :items="paymentMethodOptions"
                                    v-model="paymentInfo.payment_method"
                                    @change="checkStatus">
                                    </v-select>
                                </div>
                                <v-container>
                                    <v-text-field
                                    prepend-icon="mdi-calendar"
                                    @click:prepend="redirectPaymentDate"
                                    @click="redirectPaymentDate"
                                    label="Payment date"
                                    v-model="paymentInfo.payment_at">

                                    </v-text-field>
                                </v-container>
                                <v-dialog v-model="isPaymentDate" height="auto" width="auto">
                                    <v-card class="pa-3">
                                        <div class="my-1">
                                            <p class="d-flex justify-start mt-3">
                                                Payment date :
                                            </p>
                                            <div class="d-flex justify-start" >
                                                <v-date-picker
                                                v-model="paymentInfo.payment_at"
                                                :landscape="true"
                                                :reactive="true"></v-date-picker>
                                            </div>
                                        </div>
                                        <slot name="footer">
                                                <div class="d-flex justify-end my-3" color="white">
                                                    <v-btn color="primary" plain @click="resetNextPaymentDate">
                                                        Cancel
                                                    </v-btn>
                                                    <v-btn 
                                                    @click="confirmNextPaymentDate" color="primary">
                                                        Confirm
                                                    </v-btn>
                                                 </div>
                                            </slot>
                                    </v-card>

                                </v-dialog>

                                <!-- <div class="my-1">
                                    <p class="d-flex justify-start mt-3">
                                        Invoice No. :
                                    </p>
                                    <div class="d-flex justify-start" >
                                        <v-text-field
                                        dense
                                        outlined
                                        v-model="paymentInfo.invoice_number"
                                        label="Please inser the invoice number">
                                        </v-text-field>
                                    </div>
                                </div>
                                <div class="my-1">
                                    <div class="d-flex justify-start my-3">
                                        Upload invoice :
                                    </div>
                                    <v-file-input
                                    v-model="invoices"
                                    accept="image/png, image/jpeg, .pdf"
                                    color="primary"
                                    outlined
                                    multiple
                                    label="Please choose the files"
                                    counter
                                    :show-size="1000">
                                        <template v-slot:selection="{index,text}">
                                            <v-chip
                                            v-if="index<2"
                                            color="deep-purple accent-4"
                                            dark
                                            label
                                            small>
                                                {{ text }}
                                            </v-chip>
                                            <span
                                            v-else-if="index==2" class="text-overline grey--text text--darken-3">
                                                +{{ invoices.length-2 }} Files(s)
                                            </span>
                                        </template>
                                    </v-file-input>
                                </div> -->
                                
                            </div>

                            <div class="mt-3">
                                <p>Remark</p>
                                <mavon-editor
                                style="z-index:0;height:auto"
                                defaultOpen = "edit"
                                :toolbarsFlag = "false"
                                :toolbars="markdownToolbar"
                                language="en"
                                v-model="paymentInfo.remark"
                                placeholder="Start typing your remark...">
                                </mavon-editor>
                            </div>
                        </v-col>
                            <div class="d-flex justify-end ">
                                <v-btn @click="clear" color="primary" plain>
                                    Clear      
                                </v-btn>
                                <v-btn @click = "submit"
                                :loading="this.api.isLoading"
                                color="primary">
                                    Confirm
                                </v-btn>
                            </div>
                    </v-container>
                </v-card>
                <!-- <v-card class="mt-3" v-else>
                    <v-container>
                        <v-col>
                            <v-row>
                                <div>
                                    No payment requested.
                                </div>
                            </v-row>
                        </v-col>
                    </v-container>

                </v-card> -->
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import mavonEditor from 'mavon-editor';
import AConfirmation from '../../components/common/AConfirmation.vue';
import ASuccessFour from '../../components/common/ASuccessFour.vue';
// let fs = require('fs').promises;
export default{
    components:{
    "mavon-editor": mavonEditor.mavonEditor,
    AConfirmation,
    ASuccessFour
},
    props:[
        
    ],
    data:()=>(
        {
            isConfirm:false,
            isRejected:false,
            isPaymentDate:false,
            // latestPaymentStatusForm:null,
            invoices:null,
            isPending:false,
            attachmentUploader:null,
            company:null,
            rules:[
                value=>value[0].size< 10000000 || 'File size should be less than 10 MB!',
                value=>value.length<=3 || 'Total files that can be uploaded should be less than 3 files'
            ],
            attachments:null  ,
            paymentInfo:{
                payment_method:"",
                total_amount:"",
                status:"",
                payment_at:"",
                remark:"",
                invoice_number:"",
            },
            markdownToolbar:{
                preview:false,
            },
            isSaving:false,
            isOnlineBanking:false,
            isCreditCard:false,
            isShowCalendar :false,
            isOtherAmount:false,
            isPaymentNotRequest:false,
            paymentMethod:'',
            paymentAmountOptions:['1998','2388','2888','Enter any amount'
            ],
            paymentMethodOptions:['Stripe','Bank transfer','HRDC Claim','Ipay88 No installment','Ipay88 6 months installment','Ipay88 12 months installment','Ipay88 E-wallet','Cheque',"Cash deposit"],
            paymentStatusOptions:['Success','Fail'],
            bankType:'',
            bankAccountNumber:'',
            bankTypeOptions:[
                'AmBank','Affin bank','Alias Bank',' Bank Islam', 'Cimb Bank','City bank','Public bank','Maybank','Hong leong bank','Finexus service bank'
            ],
            confirmAt:null,
            isFirstLoading:true,
            api:{
                isLoading:false,
                isError:false,
                isSuccesful:false,
                error:null,
                url:null,
                success:null,
            }
        }
    ),
    created(){
        this.api.callbackReset = () =>
        {
            this.api.isLoading = true;
            this.api.isError = false;
            this.api.isSuccesful = false;
        };
        this.api.callbackError = (e) =>
        {
            this.api.isError = true;
            this.api.isLoading = false;
            this.api.isSuccesful = false;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) =>
        {
            if(resp.status != "success")
            {
                this.api.isError = true;
                return 0;
            }
            if(resp.class=="createFormWithAttachment"){
                this.api.isSuccesful = true;
                this.api.isLoading = false;
                this.api.success="Succesfully fill in the payment status update form";
                window.scrollTo(0,0);

            }
            if(resp.class=="readAttachment"){
                this.attachments = resp.data;
                this.attachmentUploader = resp.data[0].fname+" "+resp.data[0].lname;
            }
            if(resp.class=="Form"){
                if(!resp.data){
                    let date = new Date();
                    let day = date.getDate();
                    let month = date.getMonth()+1;
                    let year = date.getFullYear();
                    this.paymentInfo = {
                        payment_method:"",
                        total_amount:"",
                        status:"",
                        payment_at:year+'-'+month+'-'+day,
                    }
                    this.api.isLoading = false;
                    this.api.isSuccesful = true;
                    this.isSaving = true;
                    return 0
                }
                this.paymentInfo = JSON.parse(resp.data);
                this.paymentInfo.payment_at = this.paymentInfo.payment_at.split(' ')[0].replaceAll('/','-');
                this.api.isLoading = false;
                this.api.isSuccesful = true;
            }
            if(this.api.url == process.env.VUE_APP_SERVER_API+"/form/"+this.company)
            {
                return 1;
            }
            if(resp.class=='Company')
            {
                this.$store.commit('updateCompany',resp.data[0]);
            }
            return 1;
        }
    },

    mounted()
    {
        
        this.fetch();

           
    },
    methods:{
        fetch(){
            this.company = this.$router.history.current.params.id;
            window.scrollTo(0,0);
            this.api.isFirstLoading = false;
            this.api.isLoading = false;
           
        },
        redirectPaymentDate(){
            this.isPaymentDate = true;
        },
        saveAttachment()
        {
            let formData = new FormData();
            this.attachments.forEach(element => {
                formData.append('attachment[]',element);
            });
            formData.append('status','Pending');
            formData.append('formType','Payment');
            formData.append('userEmail',this.$store.getters.getEmail);
            formData.append('remark',this.$store.getters.getEmail+" is sending a new attachment");
            formData.append('formId',8);
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/form/attachment/"+this.company;
            tempApi.params=formData;
            return tempApi;
        },
        save()
        {
            let formData = new FormData();
            if(this.invoices!=null){
                this.invoices.forEach(element =>{
                    formData.append('attachment[]',element);
                })
                formData.append('attachmentType','Invoice');
            }
            if(this.isConfirm){
                formData.append('paymentMethod',(this.paymentInfo.payment_method)?this.paymentInfo.payment_method:null);
                formData.append('totalAmount',(this.paymentInfo.total_amount!=null)?this.paymentInfo.total_amount:null);
                formData.append('paymentAt',this.paymentInfo.payment_at);
                formData.append('invoiceNo',this.paymentInfo.invoice_number);
            }
            formData.append('formType','Payment');
            formData.append('userEmail',this.$store.getters.getEmail);
            formData.append('invoiceNo',this.paymentInfo.invoice_number)
            
            formData.append('status',(this.paymentInfo.status)?this.paymentInfo.status:null);
            formData.append('remark',this.paymentInfo.remark);
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            // tempApi.params ={
            //     'formType':'Payment',
            //     'userEmail':this.$store.getters.getEmail,
            //     'attachmentType':'Invoice',
            //     'paymentMethod':(this.paymentInfo.payment_method)?this.paymentInfo.payment_method:null,
            //     'status':(this.paymentInfo.status)?this.paymentInfo.status:null,
            //     '2':(this.paymentInfo.total_amount!=null)?this.paymentInfo.total_amount:null,
            //     'userEmail':this.$store.getters.getEmail,
            //     'paymentAt':this.paymentInfo.payment_at,
            //     'remark':this.paymentInfo.remark,
            // }
            tempApi.params = formData;
            tempApi.url = process.env.VUE_APP_SERVER_API+"/form/attachment/"+this.company;
            return tempApi;
        },
        update()
        {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "PUT";
            tempApi.params ={
                'formType':'Payment',
                'paymentMethod':(this.paymentInfo.payment_method)?this.paymentInfo.payment_method:null,
                'status':(this.paymentInfo.status)?this.paymentInfo.status:this.paymentInfo.status,
                'totalAmount':(this.paymentInfo.total_amount!=null)?this.paymentInfo.total_amount:null,
                // 'userEmail':this.$store.getters.getEmail,
            }
            tempApi.url = process.env.VUE_APP_SERVER_API+"/form/"+this.company
            return tempApi;
        },
        
        updateCompany()
        {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/companies/getsubscription/"+this.company+"?status=All";
            return tempApi;
        },
        validateInput(){
            this.isPending = true;
        },
        submit(){
            this.isPending = false
            // let firstApi =(this.$store.getters.getRole=='vision_admin')?this.save():this.saveAttachment();
            // let secondApi = this.updateCompany();
            let firstApi = this.save();
            this.$axios.all([
                this.$api.fetch(firstApi),
                this.api.isLoading = true,
                // this.$api.fetch(secondApi),
            ]).then(()=>{
                // this.api.isLoading = false;
                // this.$router.push({name:'CompaniesNotes'})
            })
        },
        cancelSubmit(){
            this.isPending = false;
        },
        checkAmount(){
            this.isOtherAmount = false;
            if(this.paymentInfo.total_amount=="Enter any amount"){
                this.isOtherAmount = true;
                this.paymentInfo.total_amount=0;
            }
        },
        checkPaymentStatus(){
            this.isConfirm = false;
            this.isRejected = false;
            if(this.paymentInfo.status=='Success'){
                this.isConfirm = true;
            }
            if(this.paymentInfo.status=="Fail"){
                this.isRejected = true;

            }
        },
        checkStatus(){
            this.isShowCalendar = true;
            this.isOnlineBanking = false;
            this.isCreditCard = false;
            if(this.paymentMethod=='Online banking'){
                this.isOnlineBanking = true;
            }
            if(this.paymentMethod =='Credit card'){
                this.isCreditCard = true;
            }
        },
        resetNextPaymentDate(){
            this.paymentInfo.payment_at =null;
            this.isPaymentDate =false;
        },
        confirmNextPaymentDate(){
            this.isPaymentDate = false;
        },
        redirectToReceipt(){
            this.$router.push({name:'PageAttachmentFormCompany',params:{
                'id':this.company,
            }});
        },
        clear(){
            // // paymentInfo:{
            //     payment_method:"",
            //     total_amount:"",
            //     status:"",
            //     payment_at:"",
            //     remark:"",
            //     invoice_number:"",
            // },
            this.paymentInfo.payment_method = null;
            this.paymentInfo.total_amount = null;
            this.paymentInfo.status="";
            this.paymentInfo.payment_at=null;
            this.paymentInfo.remark = "";
            this.paymentInfo.invoice_number="";
            this.isConfirm=false;
            this.isRejected = false;
            this.isShowCalendar=true;
            this.isOnlineBanking = false;
            this.isCreditCard = false;
        }
    }
}
</script>