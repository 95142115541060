<template>
    <v-container>
        <AConfirmation :isShow="isPendingSubmit" @cancel="cancelSubmit" @confirm="submit" />   
        <ASuccessFour :api="this.api"/>
        <v-row>
            <v-col class="mx-auto"
            cols="12"
            sm="7">
                <v-btn
                color="primary"
                class="my-3"
                @click="()=>{
                    this.$router.go(-1);
                }">
                    <v-icon>
                        mdi-arrow-left-bold
                    </v-icon>
                </v-btn>
                <v-card class="py-4">
                    <v-toolbar
                    class="secondary  ma-4">
                        <v-toolbar-title class="white--text text-h4">
                            {{ this.form.formType }} Form
                        </v-toolbar-title>
                    </v-toolbar>
                    <div class="ml-3">
                        {{ company.name }}
                    </div>
                    <v-container
                    v-if="this.company && this.form.formType=='Renewal'">
                        <div class="mx
                        -6">
                            <div
                            class="d-flex mt-4">
                                <v-select
                                dense 
                                outlined
                                deletable-chips   
                                auto
                                v-model="form.status"
                                :items="statusOptions"
                                label="status"
                                @change="checkStatus">
                                </v-select>
                            </div>
                        </div>
                        <div class=""
                        v-if="isReachable == 'Reachable'">
                            <div class="mt-3">
                                <v-select
                                dense
                                outlined
                                deletable-chips
                                v-model="form.renewalStatus"
                                :items="renewalStatusOptions"
                                label="Renewal status"
                                @change="checkStatusRenewal">
                                </v-select>
                            </div>
                            <div >
                                <div v-if="isPending">
                                    <!-- <div class="mb-1">
                                            Reason of pending
                                    </div> -->
                                    <v-select
                                    dense
                                    label="Reason of pending"
                                    outlined
                                    small-chips
                                    deletable-chips
                                    multiple
                                    chips
                                    v-model="form.reasonPending"
                                    :items = reasonPendingOptions>
                                    </v-select>
                                </div>
                                <div v-if="isRejected">
                                    <v-select 
                                    dense
                                    label="Refund"
                                    outlined
                                    v-model ="form.refund"
                                    :items = "refundOptions">
                                    </v-select>
                                </div>
                                <div v-if="form.refund==='Yes'" class="mb-4">
                                    <div class="d-flex justify-start">
                                        <v-autocomplete :items="optionPaymentMethod" v-model="form.selectedPaymentMethod" dense outlined label="Refund Method">

                                        </v-autocomplete>
                                    </div>
                                    <div v-if="form.selectedPaymentMethod=='Bank Transfer'">
                                        <div class="d-flex justify-start">
                                            <v-autocomplete :items="optionBankType" v-model="form.selectedBankType" label="Bank" dense outlined>
                                                
                                            </v-autocomplete>
                                        </div>
                                        <div class="d-flex justify-start" v-if="form.selectedBankType=='Others'">
                                            <v-text-field label="Others" v-model="form.othersSelectedBankType" dense outlined>

                                            </v-text-field>
                                        </div>
                                        <div class="d-flex justify-start">
                                            <v-text-field dense label=" Account No." v-model="form.refundBankAccount" outlined>

                                            </v-text-field>
                                        </div>
                                        <div>
                                            <v-text-field dense outlined label="Account holder name " v-model="form.selectedBankHolder">

                                            </v-text-field>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="isRejected">
                                    <v-select
                                    dense
                                    outlined
                                    label="Reason of rejection"
                                    chips
                                    deletable-chips
                                    small-chips
                                    multiple
                                    v-model="form.reasonReject"
                                    :items="reasonRejectOptions">
                                    </v-select>
                                </div>
                                <v-container v-if="isPending">
                                    <v-text-field
                                    prepend-icon="mdi-calendar"
                                    @click:prepend="redirectFollowUpDate"
                                    @click="redirectFollowUpDate"
                                    label="Next follow up date"
                                    v-model = "form.nextFollowupDate">
        
                                    </v-text-field>
                                </v-container>
                                <v-container v-if="isConfirm">
                                    <v-text-field
                                    prepend-icon="mdi-calendar"
                                    @click:prepend="redirectConfirmDate"
                                    @click="redirectConfirmDate"
                                    label="Client payment date"
                                    v-model = "form.confirmAt">

                                    </v-text-field>
                                </v-container>
                                
                                <v-dialog v-model="isNextRenewalDate"
                                height="auto"
                                width="auto" >
                                    <div
                                    class="d-flex justify-center"
                                    v-if="isNextRenewalDate && isPending">
                                        <v-card class="pa-3">
                                            <div class="d-flex flex-column">
                                                <p>Next renewal date :  </p>
                                                <div 
                                                class="">
                                                    <v-date-picker 
                                                    flat 
                                                    v-model="form.nextFollowupDate" :landscape="true" :reactive="true" >
                                                    </v-date-picker>
                                                </div>
                                            </div>
                                            <slot name="footer">
                                                <div class="d-flex justify-end my-3" color="white">
                                                    <v-btn color="primary" plain @click="resetNextFollowupDate">
                                                        Cancel
                                                    </v-btn>
                                                    <v-btn 
                                                    @click="confirmNextFollowupDate" color="primary">
                                                        Confirm
                                                    </v-btn>
                                                 </div>
                                            </slot>
                                        </v-card>
                                    </div>
                                    
                                </v-dialog>
                                  
                            </div>
                        </div>
                        <v-dialog v-model="isConfirmDate"
                        width="auto"
                        height="auto">
                            <div
                            class="d-flex justify-center"
                            v-if="form.renewalStatus=='Confirm' && showForm">
                            <v-card class="pa-3">
                                <div class="d-flex flex-column">
                                    <p>Client payment date : </p>
                                    <div 
                                    class="">
                                        <v-date-picker v-model="form.confirmAt" :landscape="true" :reactive="true" >
                                        </v-date-picker>
                                    </div>
                                </div>
                                <div name="footer">
                                    <div class="d-flex justify-end my-3">
                                        <v-btn color="primary" @click="resetConfirmDate" plain>
                                            Cancel
                                        </v-btn>
                                        <v-btn color="primary" @click="confirmConfirmDate">
                                            Confirm
                                        </v-btn>
                                    </div>
                                </div>
                            </v-card>

                            </div>
                        </v-dialog>
                           
                        </v-container>
                        <v-container
                        v-if="showForm">
                            <P>
                                Remark :
                            </P>
                            <mavon-editor
                            id="renewalRemark"
                            :rules="rules.required"
                            style="z-index:0;height:auto"
                            defaultOpen = "edit"
                            :toolbarsFlag = "false"
                            :toolbars="markdownToolbar"
                            language="en"
                            v-model="form.remark"
                            placeholder="Start typing your remark...">
                            </mavon-editor>
                        </v-container>
                        <!-- <v-btn @click="redirectFollowUpDate" v-if="isPending" color="primary">
                            Choose next follow up date
                        </v-btn>   -->
                   
                    <div class="d-flex justify-end mt-2 mr-3">
                        <v-btn color="primary" plain
                        @click="clear">
                            Clear      
                        </v-btn>
                        <v-btn color="primary"
                        :loading="api.isLoading"
                        @click="submit">
                            Confirm
                        </v-btn>
                    </div>
                    <div class="d-flex justify-end mt-2 ma-2">
                        <AError
                        :api="this.api"/>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>

</template>
<script>
import mavonEditor from 'mavon-editor'
import ASuccessFour from '../../components/common/ASuccessFour.vue';
import AConfirmation from '../../components/common/AConfirmation.vue';
    export default{
        components:{
    "mavon-editor": mavonEditor.mavonEditor,
    ASuccessFour,
    AConfirmation,
},
        computed:{

        },
        props:[
        ],
        data:()=>({
            optionPaymentMethod:['Credit Card',"Bank Transfer"],
            optionBankType:['Affin Bank','Agrobank','AmBank','Bank Islam','Bank Simpanan Nasional(BSN)','CIMB Bank','Hong Leong Bank','HSBC Bank Malaysia','Maybank','Public Bank','RHB Bank'],
            rules:{
                required:v=>!!v || 'Remark is required',
            },
            form:{
                remark:null,
                status:"",
                renewalStatus:"",
                reasonReject:null,
                reasonPending:null,
                formType:null,
                userEmail:null,
                userId:null,
                paymentMethod:null,
                confirmAt:null,
                bankType:null,
                bankAccountNumber:null,
                refund:"No",
                refundAt:null,
                nextFollowupDate:null,
                reasonRefund:null,
                selectedBankType:null,
                othersSelectedBankType:null,
                selectedPaymentMethod:null,
                refundBankAccount:null,
                selectedBankHolder:null, 
            },
           
            markdownToolbar:{
                preview:false,
                // bold:true,
                // italic:true,
                // header:true,
                // underline:true,
                // strikethrough:true,
                // superscript:true,
                // subscript:true,
                // quote:true,
                // ol:true,
                // ul:true,
                // link:true,
                // alignleft:true,
                // aligncenter:true,
                // alignright:true,
                // preview:true,
                // redo:true,
                // undo:true,

            },
            isConfirmDate:false,
            isNextRenewalDate:false,
            isOnlineBanking:false,
            isRejected:false,
            isPendingSubmit:false,
            isPending:false,
            isConfirm:false,
            isFollowup:false,
            isReachable:"",
            isRefund:false,
            showForm:false,
            company:null,
            bankTypeOptions:['Affin Bank','Agrobank','AmBank','Bank Islam','Bank Simpanan Nasional(BSN)','CIMB Bank','Hong Leong Bank','HSBC Bank Malaysia','Maybank','Public Bank','RHB Bank'],
            statusOptions:['Reachable','Unreachable'],
            paymentMethodOptions:['Credit card','Online banking'],
            renewalStatusOptions:['Pending','Confirm','Confirm - Pending HRDC','Rejected'],
            reasonRejectOptions:['Stripe auto deduct','No time to use','Never use','Have other tax advisor','Management did not approve','Learned all already','Too expensive','No reason given','Others'],
            reasonPendingOptions:['Considering','BDO liasing','To update card details','Change payment method','Payment failed','Waiting for management approval','HRDC claim','Delay renewal to a later date'],
            refundOptions :["Yes","No"],
            api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
        }),
        created(){
            this.api.callbackReset = () => {
                this.api.isLoading = true;
                this.api.isError = false;
            };
            this.api.callbackError = (e) => {
                this.api.isLoading = false;
                this.api.isError = true;
                this.api.error = e;
            };
            this.api.callbackSuccess = (resp) => {
                if(resp.class=="createForm"){
                    this.api.isSuccesful = true;
                    this.api.success = "Succesfully fill in the renewal form";
                }
                if(resp.status == "success")
                {
                    if(this.api.url ==  process.env.VUE_APP_SERVER_API+"/companies/getsubscription/"+this.company+"?status=All")
                    {
                        this.$store.commit('updateCompany',resp.data[0]);
                    }
                    return resp.data;
                }
            };
        },
        mounted(){
            this.fetch();
        },
        methods:{
            fetch(){
                // if(this.$store.getters.getCompany==null)
                // {
                //     // this.$router.push({name:'PageErrorNotFound'});
                // }
                
                this.form.userEmail = this.$_.clone(this.$store.getters.getEmail);
                this.form.formType = this.$_.clone(this.$store.getters.getAction);

                this.company = this.$router.history.current.params.id;
                // this.form.bankType = (this.company.bank_type)?this.company.bank_type:null;
                // this.form.bankAccountNumber = (this.company.bank_account_number)?this.company.bank_account_number:null;
                // this.fetchFollowup();
                
            },
            fetchFollowup(){
                let date = new Date();
                let day = date.getDate();
                let month = date.getMonth()+1;
                let year = date.getFullYear();
                this.form.confirmAt = year+'-'+month+'-'+day;
            },
            fetchCompany()
            {
                this.api.method = "get";
                this.api.url= process.env.VUE_APP_SERVER_API+"/companies/getsubscription/"+this.company+"?status=All";
                this.$api.fetch(this.api).then(()=>{
                    this.$router.push({name:'PageCompaniesDetail'})
                });
            },
            redirectFollowUpDate(){
                this.isNextRenewalDate = true;
            },
            redirectConfirmDate(){
                this.isConfirmDate = true;
            },
            checkStatus(){
                this.showForm = true;
                this.isOnlineBanking = false;
                // this.form.bankType = null;
                // this.form.bankAccountNumber = null;
                if(this.form.paymentMethod=="Online banking")
                {
                    this.isOnlineBanking = true;
                }
                if(this.form.formType == "Payment")
                {
                    return 1;
                }
                if(this.form.status=='Reachable'){
                    this.isReachable = "Reachable";
                    return 1;
                }
                this.form.renewalStatus = "Unreachable";
                this.form.reasonReject = null;
                this.isReachable = "Unreachable";
                
            },
            checkStatusRenewal(){
                this.form.reasonReject = null;
                this.form.reasonPending = null;
                this.form.confirmAt = null;
                this.form.refundAt =null;
                this.isRejected = false;
                this.isPending = false;
                this.isConfirm = false;
                if(this.form.renewalStatus == 'Rejected')
                {
                    this.isRejected = true;
                }
                if(this.form.renewalStatus == 'Pending')
                {
                    this.isPending = true;
                }
                if(this.form.renewalStatus=="Confirm")
                {
                    this.isConfirm = true;
                }
            },
            clear(){
                
                this.showForm= false
                this.form.remark = null,
                this.form.renewalStatus = null,
                this.form.status = null;
                this.form.confirmAt = null
                this.form.reasonReject= null;
                this.form.reasonPending = null;
                this.form.refundAt=null;
                this.form.nextFollowupDate=null,
                this.isNextRenewalDate=false;
                this.form.refund="No";
                this.form.refundAt=null;
                this.isConfirm=false;
                this.isPending = false;
                this.isFollowup = false;
                this.isRefund = false;
                this.isRejected = false;
                this.form.remark=null;
                this.form.selectedBankHolder=null;
                this.form.selectedBankType=null;
            },
            confirmConfirmDate(){
                this.isConfirmDate = false;
            },
            confirmNextFollowupDate(){
                this.isNextRenewalDate = false;
            },
            resetNextFollowupDate(){
                this.form.nextFollowupDate = null;
                this.isNextRenewalDate=false
            },
           resetConfirmDate(){
                this.form.confirmAt=null;
                this.isConfirmDate=false;
            }, 
            validateInput(){
             
                this.isPendingSubmit = true;
            },
            cancelSubmit(){
                this.isPendingSubmit = false;
            },
            submit(){
                //BOC: validate input
                this.isPendingSubmit = false;
                this.api.isError = false;
                if(this.form.renewalStatus == "Rejected" && this.form.reasonReject==null){
                    this.api.isError = true;
                    this.api.error = "Please fill in the reason for client rejection";
                    return 0;
                }
                if(this.form.remark==""){
                    this.api.isError = true;
                    this.api.error = "Please fill in the remark";
                    return 0;
                }
                if(this.form.renewalStatus == "Pending" && this.form.reasonPending==null){
                    this.api.isError = true;
                    this.api.error = "please fill in the reason for client pending";
                    return 0;
                }
                if(this.isReachable == "Reachable" && this.form.renewalStatus=="")
                {
                    this.api.isError = true;
                    this.api.error = "Please fill in renewal status";
                    return 0 ;
                }
                //EOC:
               
                this.showForm  = false;
                if (this.form.reasonReject!=null){
                    this.form.remark = "**Reason** **to** **reject** : **"+this.form.reasonReject+"**<br/>"+this.form.remark;
                }
                if(this.form.reasonPending!=null)
                {
                    this.form.remark = "**Reason** **to** **pending** : **"+this.form.reasonPending+"**<br/>"+this.form.remark;
                }
                if(this.form.selectedBankType=='Others'){
                    this.form.selectedBankType=this.$_.clone(this.form.othersSelectedBankType);
                }
                this.form.userId = this.$store.getters.getUserId;
                this.api.method = "post";
                this.api.params = this.form;
                this.api.url = process.env.VUE_APP_SERVER_API+"/form/"+this.company;
                this.$api.fetch(this.api);
            }
        },
    }
</script>
<style>

.v-select{
    /* overflow:visible,  */
     z-index:2;
}
</style>