<template>
    <v-container>
        <PaymentStatusUpdateForm
        v-if="action=='Payment'"/>
        <RenewalUpdateForm
        v-if="action=='Renewal'"/>
        <ReceiptForm
        v-if="action=='RequestForm'"/>
    </v-container>
</template>
<script>
import PaymentStatusUpdateForm from './PaymentStatusUpdateForm.vue';
import ReceiptForm from './ReceiptForm.vue';
import RenewalUpdateForm from './RenewalUpdateForm.vue';

    export default{
    components: { PaymentStatusUpdateForm, RenewalUpdateForm, ReceiptForm },
    data: () => ({
        action:null,
        api: {
            isLoading: false,
            isError: false,
            error: null,
            url: "",
        },
        company:null,
    }),
    created() {
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            // if(this.$store.getters.getAction==null || this.$store.getters.getCompany==null)
            // {
            //     this.$router.push({name:'PageErrorNotFound'});
            // }
            this.company  = this.$router.history.current.params.id;
            this.action = this.$store.getters.getAction;
            
        },
    },
}
</script>