<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-row class="mt-3">
            <v-col align="center" justify="center" cols="12" sm="8" class="mx-auto">
                <v-col align="start" justify="start">
                    <v-btn
                    @click="redirectBack"
                    class="mb-3"
                    color="primary">
                        <v-icon>
                            mdi-arrow-left-bold
                        </v-icon>
                    </v-btn>
                </v-col>
                <v-card class="pa-3" elevation-1>
                    <v-toolbar color="secondary" class="white--text mb-4">
                        Request payment update
                    </v-toolbar>
                    <AError :api="this.api"/>
                    <ASuccessFour :api="this.api"/>
                    <!-- <ASuccessThree :api="this.api"/> -->
                    <!--BOC : Create a form here-->
                    <v-container>
                        <!-- <div class="d-flex flex-column">
                            <v-file-input
                            :rules="rules"
                            accept="image/png, image/jpeg , .pdf"
                            color="primary"
                            v-model="attachments"
                            outlined
                            multiple
                            label="Please choose the files"
                            counter
                            :show-size="1000">
                                <template v-slot:selection="{index,text}">
                                    <v-chip
                                    v-if="index<2"
                                    color="deep-purple accent-4"
                                    dark
                                    label
                                    small>
                                        {{ text }}
                                    </v-chip>
                                    <span
                                    v-else-if="index==2" class="text-overline grey--text text--darken-3">
                                        +{{ attachments.length-2 }} Files(s)
                                    </span>
                                </template>
                            </v-file-input>
                        </div> -->
                        <v-container>
                            <P class="d-flex justify-start">
                                Remark :
                            </P>
                            <mavon-editor
                            style="z-index:0;height:auto"
                            defaultOpen = "edit"
                            :toolbarsFlag = "false"
                            language="en"
                            v-model="remark"
                            placeholder="Start typing your remark...">
                            </mavon-editor>
                        </v-container>
                        <div class="d-flex justify-end">
                            <v-btn :loading="api.isLoading" color="primary" @click="validateInput">
                                Submit
                            </v-btn>
                        </div>
                    </v-container>
                    <!--EOC-->
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import AConfirmation from '../../components/common/AConfirmation.vue';
// import ASuccessThree from '../../components/common/ASuccessThree.vue';
import ASuccessFour from '../../components/common/ASuccessFour.vue';
import AError from '../../components/common/AError.vue';
import mavonEditor from 'mavon-editor';
export default {
    components:{
    AConfirmation,
    ASuccessFour,
    AError,
    "mavon-editor":mavonEditor.mavonEditor,
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        isPending:false,
        rules:[
            value=>value[0].size < 10000000 || 'File size should be less than 10 MB!',
            value=>value.length<=3 || 'Total files that can be uploaded should be less than 3 files',
        ],
        attachments:null,
        remark:null,
        api:{
            isSuccesful:false,
            isError:false,
            isLoading:false,
            error:null,
            url:null,
            success:null,
        },
        companyId:null,
    }),
    created() {
        this.api.callbackReset = () =>
        {
            this.api.isLoading = true;
            this.api.isError = false;
            this.api.isSuccesful = false;
        };
        this.api.callbackError = (e) =>
        {
            this.api.isError = true;
            this.api.isLoading = false;
            this.api.isSuccesful = false;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) =>
        {
            if(resp.class=='createFormWithAttachment'){
                this.api.success = "Succesfully request new payment update"
                this.api.isSuccesful = true;
            }
            this.api.isError = false;
            this.api.isLoading = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            this.companyId = this.$router.history.current.params.id;

        },
        redirectBack(){
            this.$router.go(-1);
        },
        validateInput(){
            this.isPending = true;
        },
        submit()
        {
            this.isPending = false;
            let createAttachmentApi = this.create();
            this.$api.fetch(createAttachmentApi);
        },
        cancelSubmit(){
            this.isPending = false;
        },
        create(){
            let tempApi = this.$_.clone(this.api);
            tempApi.methods = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+'/form/attachment/'+this.companyId;
            let formData = new FormData();
            if(this.attachments!=null){
                this.attachments.forEach(element => {
                    formData.append('attachment[]',element);
                });
                formData.append('attachmentType','Receipt');
            }
            formData.append('formType','Payment');
            formData.append('remark',this.remark);
            formData.append("userEmail",this.$store.getters.getEmail);
            formData.append("remark",(this.remark==null)?this.$store.getters.getFname+" "+this.$store.getters.getLname+" is make a new request ":this.remark);
            formData.append('status','Pending');
            tempApi.params = formData;
            return tempApi;
          
        },
    }
}
</script>